import { Tab } from "@headlessui/react";
import React, { useContext, useEffect, useState } from "react";
import { ProductContext } from "../../contexts/ProductContext/ProductContext";
import ProductCard from "./ProductCard";
import ScrollToTopButton from "./ScrollToTopButton";
import ScrollRightButton from './ScrollRightButton'
import ScrollLeftButton from "./ScrollLeftButton";
import TryOnWindow from "../TryOn/TryOnWindow";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../services/ApiService/apiService"
import { isMobile, isTablet } from 'react-device-detect'
import { MessageService } from "../../services/MessageService/messageService";
import { QueryParam } from "../../utilities/queryParam";
import LooksCard from "./LooksCard";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const ScrollContext = React.createContext()

const Catalogue = () => {
  // const productContext = useContext(ProductContext);
  // const categories = productContext.productMap;

  const [isVisible, setisVisible] = useState(false)
  const navigate = useNavigate();
  const [categories, setCategories] = useState([])
  const [productList, setProductList] = useState([])
  const [productListResponse, setProductListResponse] = useState({})
  const looks = QueryParam.getParam('looks')
  const [looksList, setLooksList] = useState([])

  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    async function fetchData() {
      const data = await ApiService.getCategories()
      if (data && data.length) {
        if (looks == 1) {
          let LooksData = await ApiService.getLooks()
          setLooksList(LooksData)
          data.push({
            active_product_count: LooksData.length,
            category: 'Looks',
            label: 'Looks'
          })
        }
        setCategories(data)

        const firstElementWithProducts = data.findIndex((category) => category.active_product_count > 0)

        const firstCategory = data[firstElementWithProducts || 0].category
        const inventory = await ApiService.getInventories(firstCategory)
        setProductList(inventory)
        let res = {}
        res[firstCategory] = inventory
        setProductListResponse(res)
      }
    }
    fetchData();
  }, [])

  useEffect(() => {
    if (isMobile || isTablet) {
      navigate("/mobile", { replace: "true" })
    }
  }, [])

  const createObserver = (target) => {
    const targetElement = target
    const options = {
      threshold: 0.2,
      root: null
    }
    //  const btn =  document.getElementById('scrollBtn')
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {

          if (entry.intersectionRatio <= 0.2) {
            setisVisible(true)
          } else {
            setisVisible(false)
          }
        })
      }, options)
    observer.observe(targetElement)
  }


  useEffect(() => {
    const target = document.getElementById('tryOn')
    createObserver(target)
  })

  const handleTabClick = async (index) => {
    if(!categories) return
    const category = categories[index].category
    if (category?.toLowerCase == "looks") {

    }
    else if (!productListResponse[category]) {
      const inventory = await ApiService.getInventories(category)
      let data = productListResponse
      data[category] = inventory
      setProductListResponse(data)
    }
    MessageService.sendMessageToRemoveAllProduct()
    setProductList(productListResponse[category])
  }

  useEffect(() => {
    handleTabClick(tabIndex)
  }, [tabIndex])

  return (
    <div className="flex  md:overflow-y-hidden flex-col h-full w-full md:flex-row"  >
      <TryOnWindow></TryOnWindow>
      <div className="px-2 h-max  sm:px-0 m-2 w-full">
        <Tab.Group selectedIndex={tabIndex} onChange={setTabIndex}>
          <div id='categoryTabParentDiv' className="relative flex w-full items-center">
            <ScrollLeftButton />
            <Tab.List id='Product_category_list' className="flex pr-6 pl-2 mx-1.5 w-90 justify-between overflow-x-auto overflow-y-hidden scrollbar-hide md:w-full md:px-1 md:w-full md:space-x-1">
              {categories.map((category, index) =>
                <Tab
                  key={`${category?.category}`}
                  disabled={!category.active_product_count > 0}
                  className={({ selected }) =>
                    classNames(
                      "px-3 mx-2.5 py-2.5 text-sm leading-5 font-medium text-stone-700  whitespace-nowrap md:mx-0 md:whitespace-normal",
                      "focus:outline-none",
                      selected
                        ? "text-stone-900 border-bottom"
                        : "text-stone-500 hover:bg-white/[0.12] hover:text-stone-900",
                      !category.active_product_count > 0 ? 'hidden' : ''
                    )
                  }
                >
                  {category.label}
                </Tab>
              )}
            </Tab.List>
            <ScrollRightButton />
          </div>
          <Tab.Panels id='card_panel' className="mt-2  h-full w-full" >
            {categories.map((category, idx) => (
              <Tab.Panel
                key={category?.category}
                className={classNames(
                  "bg-white rounded-xl p-2 flex justify-center md:justify-start"
                )}
              >
                <div
                  className=" card-panel-container flex justify-center md:justify-start flex-wrap h-auto md:overflow-auto"
                  style={{
                    maxHeight: `calc(100vh - 130px)`
                  }}
                >
                  {category?.category?.toLowerCase() !== "looks" ? productList.map((product, index) => (
                    <ProductCard
                      key={index + "card"}
                      product={product}
                      index={index}
                      category={category.category}
                    ></ProductCard>
                  )) : (

                    looksList.map((looks, index) => {
                      return <LooksCard
                        looks={looks}
                        index={index}
                        category="looks"
                      ></LooksCard>
                    }))}
                </div>
              </Tab.Panel>
            ))}
            <ScrollContext.Provider value={isVisible}>
              <ScrollToTopButton />
            </ScrollContext.Provider>
          </Tab.Panels>
        </Tab.Group>
      </div >
    </div >
  );
};

export { ScrollContext, Catalogue }
