import { useEffect, useState } from "react"
import { SkinAnalysis } from "../components/SkinAnalysis/SkinAnalysis"
import { IoGlassesOutline } from 'react-icons/io5'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { TbFaceId } from 'react-icons/tb'
import { MessageService } from "../services/MessageService/messageService"
import { Recommendations } from "../components/Recommendations/Recommendations"
import TryOnWindow from "../components/TryOn/TryOnWindow"

export const InstructionCard = ({icon, children}) => {
    return <div className="flex flex-col justify-start items-center p-6 w-60 border m-4 shadow-lg rounded-lg">
        <div className="text-8xl mb-4">{icon}</div>
        <div className="text-lg flex justify-center">{children}</div>
    </div>
}

export const SkinAnalysisInfo = () => {
    return (
        <div className="flex flex-col justify-center items-center w-full mt-8">
            <div className="text-2xl font-bold uppercase text-gray-800">Skin Analysis</div>
            <div className="text-lg text-gray-600 m-10 leading-7">
                <p>Analyze multiple skin concerns including spots, wrinkles, blackheads and many other skin issues.</p>
                <br></br>
                <p className="font-semibold">Analyze your skin in 3 simple steps:</p>
                <br></br>
                <ol className="list-decimal ml-10">
                    <li>Click on Live Camera button</li>
                    <li>Click on Analyze Skin</li>
                    <li>Wait for the results to compute</li>
                </ol>
            </div>
            <div>
                <div className="text-xl font-bold uppercase text-gray-800 text-center">Instructions</div>
                <div className="flex w-full justify-around flex-wrap">
                    <InstructionCard icon={<IoGlassesOutline></IoGlassesOutline>}>
                        Take off your glasses and make sure your hair are not covering your forehead.
                    </InstructionCard>
                    <InstructionCard icon={<HiOutlineLightBulb></HiOutlineLightBulb>}>
                        Make sure that you're in a well-lighted environment.
                    </InstructionCard>
                    <InstructionCard icon={<TbFaceId></TbFaceId>}>
                        Look straight into the camera and keep your face in the circle.
                    </InstructionCard>
                </div>
            </div>
        </div>
    )
}

export const SkinAnalyzePage = ({}) => {
    const [stats, setStats] = useState()

    const getskinAnalysisResults = (data) => {
        setStats(data)
    }

    useEffect(() => {
        MessageService.setSkinAnalysisListener(getskinAnalysisResults)
    }, [])

    return (
    <div  className="flex  md:overflow-y-hidden flex-col h-full w-full md:flex-row"  >
        <TryOnWindow skinAnalyze={true}></TryOnWindow>
        <div className="px-2 h-full  sm:px-0 m-2 w-full overflow-y-auto">
            <div className="flex">
                {
                    stats ? <SkinAnalysis stats={stats}></SkinAnalysis>
                            : <SkinAnalysisInfo></SkinAnalysisInfo> 
                }
            </div>
            <div>
                
                {
                    stats ? <>
                                <div className="text-2xl font-bold uppercase text-gray-800 text-center m-10">
                                    Recommendations based on your skin analysis
                                </div>
                                <Recommendations></Recommendations>
                            </>
                            : <></>
                }
            </div>
        </div>
    </div>
    )
}
