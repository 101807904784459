import React from "react";

const ShapesVariant = ({ type, item,selected = false}) => {
    const imageStyle = {
        // width: "100px",
        // height: "100px",
        borderRadius:"5px"
    }
  const ShapeBox= ({item})=>{
    const {label,imageURL}=item
    return (
        <div className="w-full">
          <img src={imageURL} alt="" className={`
                        invert
                        w-[100%]
                        border border-slate-800
                        hover: cursor-pointer
                        ${selected ? "border border-slate-50" : ""}
                    `} style={imageStyle}/>
        </div>
        
    );
  }

  return (
    <div className="mr-2 my-1" data-type={type}>
        <ShapeBox item={item.area}></ShapeBox>
    </div>
  );
};

export default ShapesVariant;
