import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import Overlay from "../Overlay/Overlay";
import ShapesVariant from "./ShapesVariant";

export default function ShapesPopover({ product, changeDone }) {
  const [open, setOpen] = useState(false)
  const [selectedVariant, setSelectedVariant] = useState(
    product.selectedItem && product.selectedItem.area
      ? product.selectedItem
      : product.shapeItems && product.shapeItems[0]
  );
  if (selectedVariant) {

    product.selectedItem = {
      ...(product.selectedItem || {}),
      ...selectedVariant,
    };

    return (
      <div className="flex w-full">
        <button className='bg-black w-full text-white h-8 mt-2'
          onClick={(event) => {
            event.stopPropagation();
            setOpen(true)
          }}
        >
          Choose a style
        </button>

        <Transition appear show={open} as={Fragment}>
        <Dialog as="div" 
          className="relative z-10" 
          onClose={() => {
            setOpen(false)
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full w-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div
                  as="div" 
                  className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Choose a style
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="text-sm text-gray-500">
                      <div className="flex bg-white py-1 justify-around">
                           {product.shapeItems.map((item, index) => (
                             <div
                                className="m-auto w-full"
                                key={`${product.sku}${index}`}
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  setSelectedVariant(item);
                                  changeDone(product, item);
                                }}
                             >
                               <ShapesVariant
                                 type="area"
                                 item={item}
                                 selected={
                                   (selectedVariant &&
                                     selectedVariant.area &&
                                     selectedVariant.area.key) ===
                                   (item && item.area.key)
                                 }
                               ></ShapesVariant>
                             </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-stone-500 px-4 py-2 text-sm font-medium text-white hover:bg-black focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setOpen(false)
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      </div>
    );
  }
}
