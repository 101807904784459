import { useEffect, useState } from "react"
import { SkinAnalysisTypes, transformSkinStats } from "../../utilities/SkinAnalysisUtils"
import { StatCircle } from "./StatCircle"

export const SkinAnalysis = ({ stats = {} }) => {

    const [renderStats, setRenderStats] = useState({})

    useEffect(() => {
        if(stats) {
            setRenderStats(transformSkinStats(stats))
        }
    }, [stats])
    
    return (
        <div className="flex flex-col justify-center items-center w-full mt-8">
            <div className="text-2xl font-bold uppercase tex-gray-800">Analysis Results</div>
            <div className="flex flex-wrap px-10 py-8 justify-center items-start">
                {
                    Object.keys(renderStats).map(type => {
                        let areas = renderStats[type]?.areas
                            return <>
                                {
                                    areas && areas.map((area, index) => {
                                        return (
                                            <StatCircle key={index} stat={area}></StatCircle>
                                        )
                                    })
                                }
                            </>                
                    })
                }    
            </div>
            
        </div>
    )
} 