import React, { useState } from 'react';
import { DEFAULT_PRODUCT_MAP } from './DefaultProductMap';

const ProductContext = React.createContext();

const ProductContextProvider = ({children}) => {
    const [productMap, setProductMap] = useState(DEFAULT_PRODUCT_MAP)

    return <ProductContext.Provider value={{
        productMap, setProductMap
    }}>
        {children}
    </ProductContext.Provider>
}

export { ProductContext, ProductContextProvider };