import { QueryParam } from "../../utilities/queryParam";

class ApiService {
    constructor() {
        this.brandId = null
        if (window?.location?.hostname === 'beauty.mirrar.com') {
            this.brandId = 'f68094c0-ed2e-4def-b2a9-6abcbe1a1726'
        } else {
            this.brandId = QueryParam.getParam("brand_id") || '4a65a544-0d45-4388-8076-001a6bec4c68'
        }
        QueryParam.setParam('brand_id', this.brandId)

        this.host = process.env.REACT_APP_HOST
    }

    async getCategories(){
        let fetchedData = await fetch(
            `${this.host}/category/beauty/${this.brandId}`
        ).then((response) => {
            if (response.status===200) {
            return response.json();
            }

            throw new Error(response)
        })
        .catch((err) => {
            console.log(err)
        })
        if(fetchedData && fetchedData.data){
            return fetchedData.data
        }
    }
    async getLooks(){
        let fetchedData = await fetch(
            `${this.host}/looks/beauty/${this.brandId}`
        ).then((response) => {
            if (response.status===200) {
            return response.json();
            }

            throw new Error(response)
        })
        .catch((err) => {
            console.log(err)
        })
        if(fetchedData && fetchedData.data){
            return fetchedData.data
        }
    }

    async getInventories(category){
        const params=
            {
                limit:20,
                product_code : "",
                filter_field: {
                    disable:0,
                    page : 1
                }
            }
          
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(params),
          };
        let fetchedData = await fetch(
            `${this.host}/product/beauty/${this.brandId}/categories/${category}/inventories`,requestOptions
        ).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error(response)
        })
        .catch((err) => {
            console.log(err)
        })
        if(fetchedData && fetchedData.data){
            return fetchedData.data
        }
    }
    async getInventoriesLooks(looks){
        const params=
            {
                limit:20,
                product_code : "",
                filter_field: {
                    disable:0,
                    page : 1
                }
            }
          
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(params),
          };
        let fetchedData = await fetch(
            `${this.host}/product/beauty/${this.brandId}/look/${looks}/inventories`,requestOptions
        ).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error(response)
        })
        .catch((err) => {
            console.log(err)
        })
        if(fetchedData && fetchedData.data){
            return fetchedData.data
        }
    }
}

let apiService = new ApiService();

export { apiService as ApiService };
