import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import Overlay from "../Overlay/Overlay";
import Variant from "./Variant";

export default function ShadesPopover({ product, changeDone }) {
  const [selectedVariant, setSelectedVariant] = useState(product?.variants[0]);
  product.selectedItem = product?.variants[0];
  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
        changeDone(product, selectedVariant);
      }}
      className="w-full max-w-sm relative"
    >
      <Disclosure className="relative">
        {({ open, close }) => (
          <>
            <Disclosure.Button
              className={`
                            group mt-2 px-2 p-1 flex justify-between items-center w-full border rounded-sm rounded-b-none border-stone-400
                            `}
            >
              <span className="text-stone-700">Shades</span>
              <div className="inline-flex items-center">
                <Variant
                  key={`${product.product_code}`}
                  type={product.category}
                  item={selectedVariant}
                ></Variant>
                <ChevronDownIcon
                  className={`
                                    h-5 w-5 text-stone-700 group-hover:text-opacity-100 transition ease-in-out duration-150`}
                  aria-hidden="true"
                />
              </div>
            </Disclosure.Button>
            <Overlay
              extraClasses="opacity-0"
              show={open}
              onClick={() => {
                close();
              }}
            />
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              {open ? (
                <div>
                  <Disclosure.Panel
                    static
                    className="absolute w-full z-20 shadow-md shadow-stone-400 border border-t-0 rounded-sm rounded-t-none border-stone-400"
                  >
                    <div className="overflow-hidden shadow-lg">
                      <div className="flex bg-white p-1">
                        {product.variants.map((item, index) => (
                          <div
                            key={`${product.sku}${index}`}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              setSelectedVariant(item);
                              changeDone(product, item);
                            }}
                          >
                            <Variant
                              type={product.category}
                              item={item}
                              selected={selectedVariant === item}
                            ></Variant>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Disclosure.Panel>
                </div>
              ) : (
                <></>
              )}
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
}
