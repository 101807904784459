import { ShoppingCartIcon } from "@heroicons/react/solid";

export function SuccessNotification({ title, message, dismiss }) {
    return (
      <div className="w-[22rem] bg-white mt-4 px-4 py-2 shadow-lg rounded flex justify-between items-center text-stone-700">
        <div>
            <ShoppingCartIcon className="w-8 h-8"></ShoppingCartIcon>
        </div>
        <div>
            <p className="font-bold">{title}</p>
            <p className="">{message}</p>
        </div>
        <div className="ml-1">
            <button
            type="button"
            className="bg-stone-800 text-white p-2 rounded-md"
            onClick={dismiss}
            >
            Dismiss
            </button>
        </div>
  
      </div>
    );
  }
  
  export function InfoNotification({ title, message, dismiss }) {
    return (
      <div className="w-80 bg-blue-500 border border-gray-200 px-4 py-2 shadow-lg rounded">
        <p className="font-bold text-blue-100">{title}</p>
  
        <p className="text-blue-300">{message}</p>
  
        <button
          type="button"
          className=" text-blue-200 font-semibold mt-2 hover:text-blue-100"
          onClick={dismiss}
        >
          Dismiss
        </button>
      </div>
    );
  }
  
  export function WarningNotification({ title, message, dismiss }) {
    return (
      <div className="w-80 bg-yellow-500 border border-gray-200 px-4 py-2 shadow-lg rounded">
        <p className="font-bold text-yellow-100">{title}</p>
  
        <p className="text-yellow-300">{message}</p>
  
        <button
          type="button"
          className=" text-yellow-200 font-semibold mt-2 hover:text-yellow-100"
          onClick={dismiss}
        >
          Dismiss
        </button>
      </div>
    );
  }
  
  export function DangerNotification({ title, message, dismiss }) {
    return (
      <div className="w-80 bg-red-500 border border-gray-200 px-4 py-2 shadow-lg rounded">
        <p className="font-bold text-red-100">{title}</p>
  
        <p className="text-red-300">{message}</p>
  
        <button
          type="button"
          className=" text-red-200 font-semibold mt-2 hover:text-red-100"
          onClick={dismiss}
        >
          Dismiss
        </button>
      </div>
    );
  }