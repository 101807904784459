import React from "react";
import ShadesPopover from "./ShadesPopOver";
import ShapesPopover from "./ShapesPopOver";
import {
  ProductCardClickHandler,
  ProductDelesectionHandler,
} from "./ProductCardClick";
import { CardService } from "../../services/ProductServices/cardServices";

const ProductCard = ({ product, index, category }) => {
  product.category = category;
  return (
    <div
      onClick={function (event) {
        console.log({ product, index, category });
        if (CardService.higlightSelectedProduct(product)) {
          // the card is selected, so deselect it
          ProductDelesectionHandler({ product, index, category });
        } else {
          CardService.removeAllProductSelected()
          ProductCardClickHandler({ product, index, category });
        }
      }}
      data-category={category}
      key={product.product_code}
      id={product.product_code}
      data-identifier={CardService.getKey(product)}
      className={
        "text-left h-670 mb-8 max-w-250  md:h-500 md:max-w-200 flex flex-col bg-white rounded-md hover:shadow-xl cursor-default hover:cursor-pointer p-2 m-2 mr-4 " +
        CardService.higlightSelectedProduct(product)
      }
    >
      <div className="w-full">
        <img src={product.product_images[0]} className="w-full aspect-square" alt="Product" />
      </div>
      <h3 className="text-md font-medium leading-5 text-stone-700 pt-2">
        {product.product_title}
      </h3>
      <p className="text-sm font-medium leading-5 text-stone-600 py-1 px-1 grow">
        {product.product_code}
      </p>
      <div className="flex px-1">
        {
          product.variants ?
            (<ShadesPopover
              key={product.product_code + "shades"}
              product={product}
              changeDone={(product, item) => {
                product.selectedItem = item;
                ProductCardClickHandler({ product, index, category });
              }}
            ></ShadesPopover>)
            : ''
        }
      </div>
      <div className="flex px-1">
        <ShapesPopover
          key={product.sku + "shades"}
          product={product}
          type="area"
          changeDone={(product, item) => {
            product.selectedItem = {
              ...product.selectedItem,
              ...item,
            };
            ProductCardClickHandler({ product, index, category });
          }}
        ></ShapesPopover>
      </div>
    </div>
  );
};

export default ProductCard;
