import {FaCaretUp} from 'react-icons/fa'
import React, {useContext} from "react";
import { ScrollContext } from './Catalogue';

const ScrollToTopButton = () => { 
  const visible = useContext(ScrollContext)

  const scrollToTop = () =>{
    const scrolled = document.getElementById('parentDiv');
    scrolled.scroll({
      top: 0, 
      behavior: 'smooth'
    });
  };
  
  return (
    <button id ='scrollBtn'
      onClick={scrollToTop}
      className = {
          visible ? `fixed bottom-3 right-1 px-3 py-1.5
                      flex justify-center content-center items-center rounded-md md:hidden
                      rounded-md
                      text-white bg-stone-700` 
                  : 'hidden md:hidden'}
    > Try On <FaCaretUp className='ml-1'/>
    </button>
  )
}

export default ScrollToTopButton

