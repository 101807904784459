import * as $ from "jquery/dist/jquery.min.js"
import {FaChevronRight}  from 'react-icons/fa'

const ScrollRightButton = () => { 

    const disableRightScrollButton = () => {
    const rightScrollBtnSvg = document.getElementById('chevronRight')
    rightScrollBtnSvg.style.display = 'none'
    }

    const scrollRight = () => {
      const productCategoryList = document.getElementById('Product_category_list')
      if (productCategoryList.scrollLeft < productCategoryList.scrollWidth - productCategoryList.offsetWidth) {
        $(productCategoryList).animate({
          scrollLeft: '+=100px'
        }, 'slow', () => {
          const leftScrollBtnSvg = document.getElementById('chevronLeft')
          leftScrollBtnSvg.style.display =  'flex'
        })
      } 
      
      if (productCategoryList.scrollLeft > productCategoryList.scrollWidth - (productCategoryList.offsetWidth + 150) ) {
        disableRightScrollButton()
      }

    };
    
    return (
 
      <button id ='scrollRightBtn'
        onClick={scrollRight}
        className = 'md:hidden'
        style = {{
          position: 'absolute',
          right: '3px'

        }}
      >
          <FaChevronRight id ='chevronRight' 
          style = {{
            display: 'flex'
          }}/>
      </button>
      
    )
  }
  export default ScrollRightButton