import { ChevronDoubleRightIcon, ChevronRightIcon } from "@heroicons/react/solid"
import Variant from "../Catalogue/Variant"

export const DetailsProductCard = ({ product, item }) => {
    
    return (
        <div
            key={product.sku}
            id={product.sku}
            className={`
                    text-left my-4
                    flex 
                    bg-white rounded-md cursor-default
                `}
        >
            <div className="w-3/5">
                <img src={product.image} className="w-full" alt="Product" />
                <div 
                    className={`
                                flex p-2 mt-2 justify-between items-center w-full
                                group border rounded-sm border-stone-400 text-lg
                            `}
                >
                    <div>Shade</div>
                    <Variant
                        type={product.type}
                        item={item}
                        additionalClasses={`w-6 h-6`}
                    ></Variant> 
                </div>
            </div>
            <div className="ml-4 flex flex-col justify-between">
                <div>
                    <h3 className="text-3xl font-extrabold text-stone-700 pt-2 px-1">
                        {product.name}
                    </h3>
                    <p className="mt-2 text-md font-semibold leading-5 text-stone-600 py-2 px-1 grow">
                        {product.description}
                    </p>
                    <h3
                        className="text-xl font-extrabold leading-5 text-stone-700 pt-2 px-1"
                    >
                        Rs. 10000
                    </h3>
                </div>

                <div 
                    className="mb-3 mr-2 w-full text-stone-600 font-bold flex text-xl items-center justify-end"
                >
                   <ChevronDoubleRightIcon className="w-6 h-6"></ChevronDoubleRightIcon> More Details
                </div>
            </div>
        </div>
    )
}