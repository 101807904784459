import React from 'react'
import { ProductContextProvider } from '../../contexts/ProductContext/ProductContext'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import TryOnWindow from '../TryOn/TryOnWindow'

export const Layout = ({ children }) => {
    return (
        <>
        <Header></Header>
        <ProductContextProvider>
            <main
                style={{
                height: "calc(100% - 50px)",
                }}
            >
                { children }
            
            </main>
        </ProductContextProvider>
        <Footer></Footer>
        </>
    )
}