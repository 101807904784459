export const SkinAnalysisTypes = {
    'wrinkle': { label: 'Wrinkles'},
    'blackhead': { label: 'Blackheads'},
    'acne': { label: 'Acne'},
    'dark_circle': { label: 'Dark Circles'},
    'eye_finelines': { label: 'Eye Finelines' },
    'eye_pouch': { label: 'Eye Pouch' },
    'eyelid': { label: 'Eye Lid' },
    'mole': { label: 'Mole' },
    'pores': { label: 'Pores' },
    'skin_spot': { label: 'Skin Spot' },
    'skin_type': { label: 'Skin type' },
}

export const transformSkinStats = (stats) => {
    let transformedStats = {}

    Object.keys(SkinAnalysisTypes).forEach((type) => {
        transformedStats[type] = {
            label: SkinAnalysisTypes[type].label,
            areas: []
        }
    })

    Object.keys(stats).forEach(stat => {
        let value = stats[stat]
        transformedStats[value.type]?.areas.push({
            ...value,
            label: stat.replaceAll('_', ' ')
        })
    })
    return transformedStats 
}