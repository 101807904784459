import * as $ from "jquery/dist/jquery.min.js"
import {FaChevronLeft} from 'react-icons/fa'



const ScrollLeftButton = () => { 

    const disableLeftScrollButton = () => {
      const leftScrollBtnSvg = document.getElementById('chevronLeft')
      leftScrollBtnSvg.style.display = 'none'

    }

    const scrollLeft = () =>{
        const productCategoryList = document.getElementById('Product_category_list')
        const firstTab = productCategoryList.firstChild

        if (productCategoryList.scrollLeft > firstTab.offsetLeft) {
          $(productCategoryList).animate({
            scrollLeft: '-=100px'
          }, 'slow', () => {
            const rightScrollBtnSvg = document.getElementById('chevronRight')
            rightScrollBtnSvg.style.display = 'flex'
          })

        }

        if (productCategoryList.scrollLeft <= firstTab.offsetLeft + 76) {
          disableLeftScrollButton()
        }
    }


    
    return (
    
      <button id ='scrollLeftBtn'
        onClick={scrollLeft}
        className = 'md:hidden'
        style = {{
          position: 'absolute',
          left: '-12px'

        }}
      >
        <FaChevronLeft id = 'chevronLeft' 
           style = {{
            display: 'none'
          }}/>
      </button>

      
    )
}

  export default ScrollLeftButton