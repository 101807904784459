import React from "react";

export const returnColor = (color) => {
  if (typeof color === "string") {
    return color;
  } else if (typeof color === "object") {
    if (color && color.r !== undefined) {
      // ignoring a for catalogue
      // if (color.a) {
      //   return `rgba( ${color.r},${color.g},${color.b}, ${color.a} )`;
      // } else {
      return `rgb( ${color.r},${color.g},${color.b})`;
      // }
    }
  }
};

const Variant = ({ type, item, additionalClasses = 'w-6 h-6', rounded = true, selected = false }) => {

  const ColorBox = ({ color }) => {
    return (
      <div
        className={`
                        ${rounded ? "rounded-full" : "rounded-sm"} 
                        flex items-center justify-center
                        hover:cursor-pointer hover:border hover:border-stone-500
                        ${additionalClasses}
                        ${selected ? "border border-stone-500" : ""}
                    `}
      >
        <div
          className={`${rounded ? "rounded-full" : "rounded-sm"
            } w-3/4 h-3/4 hover:cursor-pointer`}
          style={{
            backgroundColor: returnColor(color),
          }}
        ></div>
      </div>
    );
  };

  return (
    <div className="mx-1 my-1" data-type={type}>
      {type === "color" ||  item.variant_color? (
        <ColorBox color={item.variant_color}></ColorBox>
      ) : (''
        // <img src={item.image} />
      )}
    </div>
  );
};

export default Variant;
