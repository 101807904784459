import { MessageService } from "../../services/MessageService/messageService";
import { CardService } from "../../services/ProductServices/cardServices";

const ProductCardClickHandler = ({ product, index, category }) => {
  CardService.deselectSpecificCategory(category);
  CardService.selectSpecificId(CardService.getKey(product));
  CardService.setProductSelected(category, product);
  // console.log(product)
  MessageService.sendMessageToRendererOnProductChange(product);
};
const ProductDelesectionHandler = ({ product, index, category }) => {
  CardService.deSelectSpecificId(CardService.getKey(product));
  CardService.removeProductSelected(product.category);
  MessageService.sendMessageToRendererOnDeselection(product);
};

const LooksCardClickHandler = ({ looks, index, category, currentLookData }) => {
  CardService.deselectSpecificCategory(category);
  CardService.selectSpecificId(looks.key);
  // CardService.setProductSelected(category, looks);
  // console.log(currentLookData)
  for (let i of currentLookData) {
    const product = {
      "product_code": i.product_code,
      "category": i.category?.category,
      "product_images": [],
      "product_meta_json": i.product_meta_json,
      "product_title": i.product_title,
      "variants": [i.variant],
      "brand_id": i.brand_id,
      "sub_brand_id": i.sub_brand_id,
      // "product_category_id": 36,
      "collection": null,
      "selectedItem": i.variant
    }
    console.log(product)
    MessageService.sendMessageToRendererOnProductChange(product);
  }
};
const LooksDelesectionHandler = ({ looks, index, category, currentLookData }) => {
  CardService.deSelectSpecificId(looks.key);
  CardService.removeProductSelected(looks.key);
  for (let i of currentLookData) {
    const product = {
      "product_code": i.product_code,
      "category": i.category?.category,
      "product_images": [],
      "product_meta_json": i.product_meta_json,
      "product_title": i.product_title,
      "variants": [i.variant],
      "brand_id": i.brand_id,
      "sub_brand_id": i.sub_brand_id,
      // "product_category_id": 36,
      "collection": null,
      "selectedItem": i.variant
    }
    MessageService.sendMessageToRendererOnDeselection(product);
  }
}

export { ProductCardClickHandler, ProductDelesectionHandler, LooksCardClickHandler, LooksDelesectionHandler };
