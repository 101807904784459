import * as $ from "jquery/dist/jquery.min.js";
class CardService {
  constructor() {
    this.selectedProduct = {};
    this.selectedItemClass = "selectedItem";
  }
  getKey(product) {
    return product ? product.product_code + "-" + product.category : null;
  }
  deselectSpecificCategory(category) {
    $(`[data-category='${category}']`).removeClass(this.selectedItemClass);
  }
  selectSpecificId(id) {
    $(`[data-identifier="${id}"]`).addClass(this.selectedItemClass);
  }
  deSelectSpecificId(id) {
    $(`[data-identifier="${id}"]`).removeClass(this.selectedItemClass);
  }
  setProductSelected(category, product) {
    this.selectedProduct[category] = product;
  }
  removeProductSelected(category) {
    this.selectedProduct[category] = null;
  }
  removeAllProductSelected() {
    this.selectedProduct = {}
  }
  getProductSelected(category) {
    return this.selectedProduct[category];
  }
  higlightSelectedProduct(product) {
    try {
      return this.selectedProduct[product.category].product_code === product.product_code
        ? this.selectedItemClass
        : "";
    } catch (error) {
      return "";
    }
  }
}

let cardService = new CardService();

export { cardService as CardService };
