import React from "react";
import { MessageService } from "../../services/MessageService/messageService";
import { QueryParam } from "../../utilities/queryParam";
import { isMobile, isTablet } from 'react-device-detect'

const TryOnWindow = ({ additionalClasses = 'items-center', skinAnalyze = false }) => {
  const opacitySlider = true
  const getDynamicMakeUpWebArLink = () => {
    let url = "http://localhost:3001";
    // console.log(process.env);
    if (process.env.REACT_APP_ENV !== "development") {
      let branchKeyWord = process.env.REACT_APP_S3_BUCKET;
      url = `https://cdn.mirrar.com/${branchKeyWord}/index.html`;
    }
    return url;
  };

  const getDynamicSearchString = (url) => {
    let newurl = new URL(url)
    for (const i of QueryParam.allowedKeys) {
      const val = QueryParam.getParam(i);
      if (val) {
        newurl.searchParams.append(i, val)
      }
    }

    if (skinAnalyze) {
      newurl.searchParams.append("skinAnalyze", true)
    }
    if (opacitySlider) {
      newurl.searchParams.append("show", "opacity")
    }

    return newurl.href;
  };

  return (
    <>
      <div id="tryOn" className={`flex h-full self-center lg:px-1 ${additionalClasses}`}>
        <div
          className={`text-white flex justify-center items-center ${isMobile ? '' : 'max-h-full'}`}
          style={{
            minWidth: (isMobile || isTablet) ? "100%" : "480px",
            height: (isTablet) ? "85%" : (isMobile) ? "80%" : "640px"
          }}
        >
          <iframe
            onLoad={function (event) {
              const iframe = event.target;
              MessageService.setIframe(iframe);
            }}
            title="Mirrar Make up Try-On"
            src={getDynamicSearchString(getDynamicMakeUpWebArLink())}
            className="w-full h-full"
            allow="camera;autoplay;microphone;clipboard-read; clipboard-write"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default TryOnWindow;
