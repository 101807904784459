import React, { useEffect, useState } from "react";
import {
    LooksCardClickHandler,
    LooksDelesectionHandler,
} from "./ProductCardClick";
import { CardService } from "../../services/ProductServices/cardServices";
import { ApiService } from "../../services/ApiService/apiService";

const LooksCard = ({ looks, index, category }) => {
    // console.log(looks)
    const [currentLookData, setCurrentLookData] = useState([])
    useEffect(() => {
        ApiService.getInventoriesLooks(looks.key).then((data) => {
            setCurrentLookData(data?.data)
        })
    }, [])
    return (
        <div
            onClick={function (event) {
                console.log({ looks, index, category });
                if (CardService.higlightSelectedProduct(looks)) {
                    // the card is selected, so deselect it
                    LooksDelesectionHandler({ looks, index, category });
                } else {
                    LooksCardClickHandler({ looks, index, category,currentLookData });
                }
            }}
            data-category={category}
            key={looks.key}
            id={looks.key}
            data-identifier={looks.key}
            className={
                "text-left h-670 mb-8 max-w-250  md:h-500 md:max-w-200 flex flex-col bg-white rounded-md hover:shadow-xl cursor-default hover:cursor-pointer p-2 m-2 mr-4 "
                // + CardService.higlightSelectedProduct(looks)
            }
        >
            <div className="w-full">
                <img src={looks.image[0]} className="w-full aspect-square" alt="Looks" />
            </div>
            <h3 className="text-md font-medium leading-5 text-stone-700 pt-2">
                {looks.label}
            </h3>
            <p className="text-sm font-medium leading-5 text-stone-600 py-1 px-1 grow">
                {looks.key}
            </p>
            <div className="flex px-1">

            </div>
        </div>
    );
};

export default LooksCard;
