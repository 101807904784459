import Variant from "../Catalogue/Variant";

export const MobileProductCard = ({ 
    product, 
    category,
    item,
    onProductClick = () => {},
    onDetailsClick = () => {}
}) => {
    return (
        <div className="pb-4 mx-2">
            <div
                key={`${product.sku}-${item.name}`}
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onProductClick()
                }}
                className={`flex flex-col items-center justify-between uppercase`}
            >
                <Variant
                    type={product && product.type?product.type:'color'}
                    item={item}
                    rounded={false}
                    additionalClasses={`w-14 h-14`}
                ></Variant>

                <div className="text-sm h-[50px]">{item.variant_color_name}</div>
                <div className="mt-2">
                    <button 
                        className="bg-black rounded-full px-3 py-1 text-white text-sm"
                        onClick={onDetailsClick}
                    >Details</button>
                </div>
            </div>
        </div>
    )
}