
export default function Overlay ({show, onClick = () => {}, extraClasses='opacity-30'}) {
    return (
        <>
        {
            show ? 
                <div className={`bg-black fixed inset-0 z-10 cursor-default ${extraClasses}`} onClick={onClick}></div> :
                <></>
        }
        </>
    )
}