const DEFAULT_FEATURE_LIST_V_30 = [
  "LipstickOneTone",
  "MascaraShortBottom",
  "MascaraShortTop",
  "MascaraShortTopBottom",
  "MascaraMediumBottom",
  "MascaraMediumTop",
  "MascaraMediumTopBottom",
  "MascaraLongBottom",
  "MascaraLongTop",
  "MascaraLongTopBottom",
  "BlushApple",
  "BlushCheekbone",
  "BlushContour",
  "FoundationFull",
  "FoundationT-Zone",
  "EyelinerTop",
  "EyelinerBottom",
  "EyelinerTopBottom",
  "EyelinerWinged",
  "Lipliner",
  "Concealer",
  "EyeshadowLid",
  "EyeshadowContour",
  "EyeshadowHighlight",
  "EyeshadowSmokey",
  "EyebrowsThin",
  "EyebrowsMedium",
  "EyebrowsThick",
  "FaceSoftener",
  "LipGloss",
  "Contour",
  "Highlighter",
  "mascara room product",
  "mascara room product",
  "mascara long",
  "LipstickOneToneLakme",
];
const DEFAULT_FEATURE_LIST_V_32 = [
  "LipstickOneTone",
  "MascaraShortBottom",
  "MascaraShortTop",
  "MascaraShortTopBottom",
  "MascaraMediumBottom",
  "MascaraMediumTop",
  "MascaraMediumTopBottom",
  "MascaraLongBottom",
  "MascaraLongTop",
  "MascaraLongTopBottom",
  "BlushApple",
  "BlushCheekbone",
  "BlushContour",
  "FoundationFull",
  "FoundationT-Zone",
  "EyelinerTop",
  "EyelinerBottom",
  "EyelinerTopBottom",
  "EyelinerWinged",
  "Lipliner",
  "Concealer",
  "EyeshadowLid",
  "EyeshadowContour",
  "EyeshadowHighlight",
  "EyeshadowSmokey",
  "EyebrowsThin",
  "EyebrowsMedium",
  "EyebrowsThick",
  "FaceSoftener",
  "LipGloss",
  "Contour",
  "Highlighter",
  "LipstickOmbreOuter",
  "LipstickOmbreInner",
  "BlushOval",
  "BlushRound",
  "BlushLong",
  "ContourOval",
  "ContourRound",
  "ContourSquare",
  "ContourHeart",
];
const DEFAULT_FINISH_LIST_V_32_30 = ["Matte", "Metallic", "Glossy", "Satin"];
const DEFAULT_PRODUCT_MAP = {
  lipstick: {
    categoryLabel: "Lipsticks",
    products: [
      {
        sku: "lipstick1",
        name: "Oh so creamy pinks! ",
        type: "color",
        feature: "lipstick",
        finish: "satin",
        description: "Bold & Beautiful",
        image: "/productImages/Lipstick1.webp",
        items: [
          {
            color: {
              r: 174,
              g: 16,
              b: 40,
              a: 0.65,
            },
            name: 'red2'
          },
          {
            color: {
              r: 171,
              g: 74,
              b: 67,
              a: 1.0,
            },
            name: 'red'
          },
          
          // {
          //   color: "#fc9c9c",
          //   name: 'red3'
          // },
        ],
      },
      {
        sku: "lipstick100",
        name: "Burnt rust! ",
        type: "color",
        feature: "lipstick",
        finish: "satin",
        description: "Make a statement",
        image: "/productImages/Lipstick2.webp",
        items: [
          {
            color: {
              r: 127,
              g: 11,
              b: 22,
              a: 0.55,
            },
            name: 'red'
          },
        ],
      },
      {
        sku: "lipstick2",
        name: "Matte Intense HD",
        description: "Your everyday favorites",
        type: "color",
        feature: "lipstick",
        finish: "satin",
        image: "/productImages/Lipstick1.webp",
        items: [
          {
            color: {
              r: 142,
              g: 11,
              b: 87,
              a: 0.65
            },
            name: 'red4'
          },
          // {
          //   color: {
          //     r: 199,
          //     g: 64,
          //     b: 71,
          //     a: 0.65,
          //   },
          //   name: 'red5'
          // },
          // {
          //   color: "#cc9fa2",
          //   name: 'red6'
          // },
        ],
      },
      {
        sku: "lipstick3",
        name: "Matte Lip Crayons",
        feature: "lipstick",
        finish: "satin",
        description: "Waterproof and long stay",
        type: "color",
        image: "/productImages/Lipstick2.webp",
        items: [
          {
            color: {
              r: 174,
              g: 30,
              b: 39,
              a: 0.65,
            },
            name: 'red7'
          },
          {
            color: {
              r: 209,
              g: 26,
              b: 44,
              a: 0.65,
            },
            name: 'red8'
          },
        ],
      },
      // {
      //   sku: "lipstick4",
      //   name: "Spotlight moisture matte",
      //   feature: "lipstick",
      //   description: "Make a statement",
      //   type: "color",
      //   image: "/productImages/Lipstick1.webp",
      //   items: [
      //     {
      //       color: {
      //         r: 230,
      //         g: 2,
      //         b: 3,
      //         a: 0.65,
      //       },
      //       name: 'red10'
      //     },
      //     {
      //       color: {
      //         r: 229,
      //         g: 55,
      //         b: 80,
      //         a: 0.65,
      //       },
      //       name: 'red11'
      //     },
      //   ],
      // },
    ],
  },
  eyeliner: {
    categoryLabel: "Eyeliner",
    products: [
      {
        sku: "eyeliner lakme style",
        name: "Express Lakme style",
        type: "color",
        feature: "eyeliner_lakme",
        finish: "satin",
        description: "Wing it like you own it",
        image: "/productImages/eyeliner.jfif",
        shapeItems: [
          {
            area: {
              key: "eyeliner_lakme-last.png",
              label: "Bottom",
              imageURL: "/productImages/Shapes/Eyeliner/eyeliner_lakme-last.png",
            },
          },
          {
            area: {
              key: "eyeliner_bottom.png",
              label: "Bottom",
              imageURL: "/productImages/Shapes/Eyeliner/eyeliner_bottom.png",
            },
          },
          {
            area: {
              key: "eyeliner_top_bottom.png",
              label: "Top Bottom",
              imageURL:
                "/productImages/Shapes/Eyeliner/eyeliner_top_bottom.png",
            },
          },
          {
            area: {
              key: "eyeliner_top.png",
              label: "Top",
              imageURL: "/productImages/Shapes/Eyeliner/eyeliner_top.png",
            },
          },
        ],
        items: [
          {
            color: {

              r: 0,
              g: 0,
              b: 0,
              a: 0.75,
            },
            name: 'black'
          },
          {
            color: {

              r: 3,
              g: 29,
              b: 128,
              a: 0.75,
            },
            name: 'blue'
          },
          {
            color: {

              r: 32,
              g: 71,
              b: 62,
              a: 0.75,
            },
            name: 'emerald'
          },
          // {
          //   color: "#ef2e73",
          //   name: 'pink'
          // },
          {
            color: {

              r: 50,
              g: 26,
              b: 55,
              a: 0.75,
            },
            name: 'purple'
          },

          {
            color: {

              r: 153,
              g: 58,
              b: 44,
              a: 0.75,
            },
            name: 'orange'
          },

        ],
      }
    ],
  },

  eye_shadow: {
    categoryLabel: "Eye Shadow",
    products: [
      {
        sku: "eyeshadowLid1",
        name: "Sugar & Spiced Basics",
        type: "color",
        feature: "eyeshadowlid",
        finish: "satin",
        description: "A twist to your daily pinks",
        image: "/productImages/eyeshadow.jpg",
        shapeItems: [
          {
            area: {
              key: "eyeshadow_contour.png",
              label: "Contour",
              imageURL: "/productImages/Shapes/EyeShadow/eyeshadow_contour.png",
            },
          },
          {
            area: {
              key: "eyeshadow_highlight.png",
              label: "Highlight",
              imageURL:
                "/productImages/Shapes/EyeShadow/eyeshadow_highlight.png",
            },
          },
          {
            area: {
              key: "eyeshadow_lid_org.png",
              label: "Lid org",
              imageURL: "/productImages/Shapes/EyeShadow/eyeshadow_lid_org.png",
            },
          },
        ],
        items: [
          {
            color: { r: 151.0, g: 89.0, b: 79.0, a: 0.5 },
            name: 'brown1'
          },
          {
            color: { r: 130.0, g: 66.0, b: 59.0, a: 0.5 },
            name: 'brown2'
          },
          {
            color: {
              r: 5,
              g: 5,
              b: 1,
              a: 0.3,
            },
            name: 'gray'
          },
        ],
      },
    ],
  },

  mascara: {
    categoryLabel: "Mascara",
    products: [
      {
        sku: "mascara1",
        name: "Everlasting Lash Talk",
        type: "color",
        feature: "MascaraShortBottom",
        description: "Hyper Curly and Voluminous Lashes",
        image: "/productImages/mascara.jpg",
        shapeItems: [
          {
            area: {
              key: "mascara_short_top.png",
              label: "Medium short Top",
              imageURL:
                "/productImages/Shapes/Mascara/mascara_short_top.png",
            },
          },
          {
            area: {
              key: "mascara_medium_top.png",
              label: "Medium Top ",
              imageURL:
                "/productImages/Shapes/Mascara/mascara_medium_top.png",
            },
          },
          {
            area: {
              key: "mascara_medium_topbottom.png",
              label: "Medium Top Bottom",
              imageURL:
                "/productImages/Shapes/Mascara/mascara_medium_topbottom.png",
            },
          },
          {
            area: {
              key: "mascara_short_topbottom.png",
              label: "short Top bottom",
              imageURL: "/productImages/Shapes/Mascara/mascara_short_topbottom.png",
            },
          },
          {
            area: {
              key: "mascara_long_topbottom.png",
              label: "Mascara Long Bottom",
              imageURL: "/productImages/Shapes/Mascara/mascara_long_topbottom.png",
            },
          },
        ],
        items: [
          {
            color: {
              r: 27,
              g: 15,
              b: 14,
              a: 0.75,
            },
            name: 'black1'
          },
          {
            color: {
              r: 9,
              g: 7,
              b: 6,
              a: 0.75,
            },
            name: 'black2'
          },
          {
            color: {
              r: 5,
              g: 5,
              b: 1,
              a: 0.75,
            },
            name: 'black3'
          },
        ],
      },
    ],
  },

  blush: {
    categoryLabel: "Blush",
    products: [
      {
        sku: "blush1",
        name: "Sheer Glow Goals",
        type: "color",
        feature: "BlushCheekbone",
        description: "Pigmented to suit all skin tones ",
        image: "/productImages/blush.jpg",
        shapeItems: [
          {
            area: {
              key: "blush_oval.png",
              label: "Oval",
              imageURL: "/productImages/Shapes/Blush/blush_oval.png",
            },
          },
          {
            area: {
              key: "blush_apple.png",
              label: "Apple",
              imageURL: "/productImages/Shapes/Blush/blush_apple.png",
            },
          },
          {
            area: {
              key: "blush_cheekbone.png",
              label: "Cheekbone",
              imageURL: "/productImages/Shapes/Blush/blush_cheekbone.png",
            },
          },
          {
            area: {
              key: "blush_long.png",
              label: "Long",
              imageURL: "/productImages/Shapes/Blush/blush_long.png",
            },
          },
          {
            area: {
              key: "blush_round.png",
              label: "Round",
              imageURL: "/productImages/Shapes/Blush/blush_round.png",
            },
          },
        ],
        items: [
          {
            color: {
              r: 206,
              g: 95,
              b: 104,
              a: 0.5,
            },
            name: 'pink'
          },
          {
            color: {
              r: 252,
              g: 76,
              b: 78,
              a: 0.35,
            },
            name: 'orange'
          },
          {
            color: {
              r: 165,
              g: 108,
              b: 108,
              a: 0.75,
            },
            name: 'brown2'
          },
          // {
          //   color: {
          //     r: 191,
          //     g: 125,
          //     b: 109,
          //     a: 0.2,
          //   },
          //   name: 'brown2'
          // },
          // {
          //   color: {
          //     r: 244,
          //     g: 163,
          //     b: 131,
          //     a: 0.2,
          //   },
          //   name: 'brown3'
          // },
          
        ],
      },
    ],
  },

  // contour: {
  //   categoryLabel: "Contour",
  //   products: [
  //     {
  //       sku: "contour1",
  //       name: "Life & Sculpt Contour Pallete",
  //       type: "color",
  //       feature: "BlushContour",
  //       finish: "satin",
  //       description: "Adds warmth and dimension",
  //       image: "/productImages/countour.jpg",
  //       items: [
  //         {
  //           color: { r: 114.0, g: 44.0, b: 65.0, a: 0.35 },
  //           name: 'brown1'
  //         },
  //         // {
  //         //   color: { r: 168, g: 115, b: 87, a: 1.0 },
  //         //   name: 'brown2'
  //         // },
  //       ],
  //     },
  //   ],
  // },
};

export {
  DEFAULT_PRODUCT_MAP,
  DEFAULT_FEATURE_LIST_V_30,
  DEFAULT_FEATURE_LIST_V_32,
  DEFAULT_FINISH_LIST_V_32_30,
};
