import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as $ from 'jquery/dist/jquery.min.js'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SkinAnalyzePage } from './pages/SkinAnalyzePage';
import { Catalogue } from './components/Catalogue/Catalogue';
import { Layout } from './components/Layout/Layout';
import { MobileCatalogue } from './components/CatalogueMobile/MobileCatalogue';
import { NotifierContextProvider } from 'react-headless-notifier';

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <NotifierContextProvider
      // All props are optional, those are the values by default
      config={{
        max: 1, // Max number of notiication simultaneously, `null` will result in no maximum
        duration: 5000, // Duration by notification in milliseconds
        position: 'top', // Default position for all the notification if it's not specify when using `notify()`, valid positions are 'top', 'topRight', 'topLeft', 'bottomRight', 'bottomLeft', 'bottom'.
      }}
    >
      <App>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Catalogue />} />
            <Route path="mobile" element={<MobileCatalogue />} />
            <Route path="skin-analyze" element={<SkinAnalyzePage />} />
          </Routes>
        </BrowserRouter>
      </App>
    </NotifierContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
