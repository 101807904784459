import React from "react"
import { QueryParam } from "../../utilities/queryParam"
import { isMobile, isTablet } from 'react-device-detect'

function getScreenSize () {
    var src =''
    // eslint-disable-next-line no-restricted-globals
    if( isMobile && isTablet) {
       src = 'mirrArTransparentLogo.png'
    // eslint-disable-next-line no-restricted-globals
    } else {
        src = 'https://i.ibb.co/94YXpZc/60a4e3838779476bfa4b9227-Logo-Mirrar-Normal-All-White.png'
    }

    return src
}

const Header = () => {

    const src = getScreenSize()
    const isIframe = QueryParam.getParam("iframe") === "true"

    return  <header 
                className={`flex justify-start items-center 
                            absolute bg-transparent  ml-5 
                            lg:static  lg:border-black lg:border-solid lg:border-b-2 lg:justify-center 
                            ${isIframe ? 'hidden': ''}
                            `}
                style={{
                    height: '50px'
                }}
            >
                <img 
                    src = {src}
                    className=""
                    style={{
                        height: '30px'
                    }}
                />
            </header>
}

export default Header