import * as $ from "jquery/dist/jquery.min.js";

class MessageService {

  constructor() {
    this.iframe = null;
    this.queue = [];
    this.skinAnalysisListener = () => { }

    window.onmessage = (windowEvent) => {
      let { data: { data, event, from }, isTrusted, } = windowEvent;
      console.log("Message caught")
      console.log(event, data, from)
      if (from && from.includes("mirrar")) {
        if (event === 'skin-analysis') {
          this.skinAnalysisListener(data)
        }
      }
    }
  }

  setSkinAnalysisListener(listener = () => { }) {
    this.skinAnalysisListener = listener
  }

  sendMessageToRendererOnProductChange(product) {
    const body = {
      event: "productChanged",
      from: "mirrar",
      data: product,
    };
    if (!this.sendEvent(body)) {
      // iframe not intialized
      // put it in queue
      this.queue.push({
        fn: "sendMessageToRendererOnProductChange",
        param: [product],
      });
    }
  }

  sendMessageToRendererOnDeselection(product) {
    const body = {
      event: "productRemoved",
      from: "mirrar",
      data: product,
    };
    if (!this.sendEvent(body)) {
      // iframe not intialized
      // put it in queue
      this.queue.push({
        fn: "sendMessageToRendererOnDeselection",
        param: [product],
      });
    }
  }
  sendMessageToChangeActiveCategory(category) {
    const body = {
      event: "categoryChanged",
      from: "mirrar",
      data: category,
    };
    if (!this.sendEvent(body)) {
      // iframe not intialized
      // put it in queue
      this.queue.push({
        fn: "sendMessageToChangeActiveCategory",
        param: [category],
      });
    }
  }
  sendMessageToRemoveAllProduct() {
    const body = {
      event: "allProductRemoved",
      from: "mirrar",
      data: [],
    };
    if (!this.sendEvent(body)) {
      // iframe not intialized
      // put it in queue
      this.queue.push({
        fn: "sendMessageToRemoveAllProduct",
        param: [],
      });
    }
  }

  getFrame() {
    return this.iframe;
  }

  sendEvent(body) {
    let iframe = this.getFrame();
    if (iframe) {
      iframe.contentWindow.postMessage(body, "*");
      return true;
    } else {
      return null;
    }
  }

  setIframe(iframe) {
    this.iframe = iframe;
    this.emptyQueue();
  }

  emptyQueue(iframe) {
    for (let i of this.queue) {
      try {
        this[i.fn](...i.param);
      } catch (error) {
        console.log(error);
      }
    }
  }
}

let messageService = new MessageService();

export { messageService as MessageService };
