import { XCircleIcon } from "@heroicons/react/solid"
import { useNotifier } from "react-headless-notifier";
import { SuccessNotification } from "../Notifications/Notifications";
import { DetailsProductCard } from "./DetailsProductCard"

export const ProductDetailTray = ({ product, item, visible = false, onTrayClose = () => {} }) => {
    const { notify } = useNotifier();
    return (
        <>
            {
                product ?
                    <div
                        style={{
                            'transition': 'max-height 0.8s ease-out'
                        }}
                        className={`
                            flex flex-col items-center justify-between
                            absolute bg-white w-full bottom-0
                            rounded-t-lg overflow-hidden
                            ${visible ? 'max-h-full' : 'max-h-0'}
                        `}
                    >
                        <div className="w-full flex justify-end p-2">
                            <button onClick={onTrayClose}>
                                <XCircleIcon className='w-10 h-10'></XCircleIcon>
                            </button>
                        </div>
                        <div className="w-full flex justify-end px-4">
                            <DetailsProductCard product={product} item={item}></DetailsProductCard>
                        </div>
                        <div className="w-full p-2 pb-6">
                            <div className="mt-2 w-full flex justify-center">
                                <button
                                    className={`
                                        px-4 py-2 w-4/5
                                        bg-white text-lg 
                                        border-2 border-black rounded-lg
                                    `}
                                    onClick={() => {
                                        notify(
                                            <SuccessNotification
                                              title="Success"
                                              message="Product Added to Cart"
                                            />
                                          );
                                    }}
                                >
                                    Add to Cart
                                </button>
                            </div>
                            <div className="mt-2 w-full flex justify-center">
                                <button
                                    className={`
                                        px-4 py-2 w-4/5
                                        bg-black text-lg text-white
                                        rounded-lg
                                        
                                    `}
                                    onClick={() => {

                                    }}
                                >
                                    Buy now
                                </button>
                            </div>
                        </div>
                    </div>
                    : <></>
            }
        </>
    )
}