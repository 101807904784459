import React, { useContext } from "react";
import { ProductContext } from "../../contexts/ProductContext/ProductContext";
import ProductCard from "../Catalogue/ProductCard"


export const Recommendations = () => {
    const productContext = useContext(ProductContext);
    const categories = productContext.productMap;
    const requiredCategories = ['blush', 'eye_shadow']
    return (
        <div 
            className="flex justify-center md:justify-start flex-wrap h-auto md:h-screen md:overflow-auto"
        >
            {
                requiredCategories.map(category => {
                    let reqCategory = categories[category]
                    return (
                        <>
                        {
                            reqCategory && reqCategory.products.map((product, index) => (
                                <ProductCard
                                key={product.sku + index + "card"}
                                product={product}
                                index={index}
                                category={category}
                                ></ProductCard>
                            ))
                        }
                        </>
                    )
                })
            }
        </div>
    )
}