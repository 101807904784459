import "./App.css";
import {Catalogue} from "./components/Catalogue/Catalogue";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import TryOnWindow from "./components/TryOn/TryOnWindow";
import { DEFAULT_PRODUCT_MAP } from "./contexts/ProductContext/DefaultProductMap";
import { ProductContextProvider } from "./contexts/ProductContext/ProductContext";
import { QueryParam } from "./utilities/queryParam";
import { ProductCardClickHandler } from "./components/Catalogue/ProductCardClick";
import { Layout } from "./components/Layout/Layout";


function App({ children }) {

  setTimeout(() => {
    try {
      const skuOfProduct = QueryParam.getParam("sku");
      if (skuOfProduct) {
        if (skuOfProduct.includes("-")) {
          const [category, sku] = skuOfProduct.split("-");
          const products = DEFAULT_PRODUCT_MAP[category].products;
          if (products && products.length) {
            for (let product of products) {
              if (product.sku === sku) {
                let index = null;
                ProductCardClickHandler({ product, index, category });
              }
            }
          }
        }
      }
    } catch (error) {}
  }, 200);

  return (
    <div id = 'parentDiv' className="overflow-y-auto scrollbar-hide overflow-x-hidden h-full relative">
      <Layout>
        { children }
      </Layout>
    </div>
  );;
}

export default App;
