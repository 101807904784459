
export const StatCircle = ({ stat = {} }) => {
    const getValue = (stat) => {
        if(stat.skin_type !== undefined) {
            switch(stat.skin_type) {
                case 0: { return "Oily" }
                case 1: { return "Dry" }
                case 2: { return "Normal" }
                case 3: { return "Mixed" }
            }
        }
        if(stat.value) {
            return "yes"
        } else {
            return "no"
        }
    }

    return (
        <div className={`w-20 flex flex-col justify-center items-center mx-3 mt-4  text-gray-600`}>
            <div 
                className={
                            `w-20 h-20 border-4 rounded-full 
                            flex justify-center items-center flex-col`
                            + ` ${stat.borderColor}`
                        }
            >
                <div className='flex items-center justify-center font-bold text-lg uppercase'>{getValue(stat)}</div>
            </div>
            <div className={`flex items-center justify-center text-center text-sm font-bold mt-2 uppercase`}>{stat.label}</div>
        </div>
    )
}