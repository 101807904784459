import { Tab } from "@headlessui/react";
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon, ChevronUpIcon } from "@heroicons/react/solid";
import React, {useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { ProductContext } from "../../contexts/ProductContext/ProductContext";
import ProductCard from "../Catalogue/ProductCard";
import { ProductCardClickHandler } from "../Catalogue/ProductCardClick";
import ScrollLeftButton from "../Catalogue/ScrollLeftButton";
import ScrollRightButton from "../Catalogue/ScrollRightButton";
import Variant from "../Catalogue/Variant";
import { Dot } from "../Separator/Dot";
import { Layout } from "../Layout/Layout";
import TryOnWindow from "../TryOn/TryOnWindow";
import { MobileProductCard } from "./MobileProductCard";
import { ProductDetailTray } from "./ProductDetailTray";
import { Line } from "../Separator/Line";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import ShapesVariant from "../Catalogue/ShapesVariant";

import { isMobile, isTablet } from 'react-device-detect'
import { ApiService } from "../../services/ApiService/apiService";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MobileCatalogue = () => {
  // const productContext = useContext(ProductContext);
  // const categories = productContext.productMap;
  const [categories,setCategories] = useState([])
  const [productList,setProductList] = useState([])
  const [productListResponse,setProductListResponse]=useState({})
  const [isDetailsVisible , setIsDetailsVisible] = useState(false)
  const [isDrawerOpen , setIsDrawerOpen] = useState(true)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [open, setOpen] = useState(false)
  const [showStylesButton,setShowStylesButton] = useState(false)
  const [selectedVariant, setSelectedVariant] = useState(null)
  const [productSelected,setProductSelected] = useState(null)

  const [tabIndex, setTabIndex] = useState(0)

  const navigate = useNavigate()

  useEffect(() => {
    async function fetchData() {
        const data=await ApiService.getCategories()
        if(data && data.length){
          setCategories(data)

          const firstElementWithProducts = data.findIndex((category) => category.active_product_count > 0)

          const firstCategory = data[firstElementWithProducts || 0].category
          const inventory=await ApiService.getInventories(firstCategory)
          setProductList(inventory)

          let res={}
          res[firstCategory]=inventory
          setProductListResponse(res)
          showOrHideStylesButton(inventory[0])
        }
    }
    fetchData();
  },[])

  const productChange = (product, index, category) => {
    if(typeof product.category !=="string" && typeof category === "string"){
      product.category=category
    }
    ProductCardClickHandler({ product, index, category })
  }

  const showOrHideStylesButton=(product)=>{
    let selectedProduct=product
    if(selectedProduct.shapeItems){
        setProductSelected(selectedProduct)
        setSelectedVariant(
            selectedProduct.selectedItem && selectedProduct.selectedItem.area
            ? selectedProduct.selectedItem
            : selectedProduct.shapeItems && selectedProduct.shapeItems[0]
        )
        setShowStylesButton(true)
    }
    else{
        setShowStylesButton(false)
    }
  }

  const handleTabClick=async(index)=>{
    if(!categories) return
    const category=categories[index].category
    if(!productListResponse[category]){
      const inventory=await ApiService.getInventories(category)
      let data=productListResponse
      data[category]=inventory
      setProductListResponse(data)
    }
    setProductList(productListResponse[category])
    showOrHideStylesButton(productListResponse[category][0])
}

  useEffect(() => {
    if(!(isMobile || isTablet)) {
        navigate("/", { replace: "true" })
    }
    
  }, [])


  useEffect(() => {
    handleTabClick(tabIndex)
  }, [tabIndex])

  return (
        <div  className="flex  flex-col h-full w-full md:flex-row"  >
            <TryOnWindow additionalClasses={`items-start bg-black w-full`}></TryOnWindow>
            <div className="absolute lg:relative bottom-0 md:px-0 w-full">
            {showStylesButton ?
                (<div>
                    <button className='relative top-10 bg-white mb-3 ml-2 w-16 h-7 font-semibold rounded'
                      onClick={(event) => {
                          event.stopPropagation();
                          setOpen(true)
                      }}
                    >
                      Styles
                    </button>
            
                    <Transition appear show={open} as={Fragment}>
                    <Dialog as="div" 
                      className="relative z-10" 
                      onClose={() => {
                        setOpen(false)
                      }}
                    >
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                      </Transition.Child>
            
                      <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                          >
                            <div
                              as="div" 
                              className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                              <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                              >
                                Choose a style
                              </Dialog.Title>
                              <div className="mt-2">
                                <div className="text-sm text-gray-500">
                                  <div className="flex bg-white p-1 justify-between">
                                       {productSelected.shapeItems.map((item, index) => (
                                         <div
                                            className="w-full"
                                           key={`${productSelected.sku}${index}`}
                                           onClick={(event) => {
                                             event.preventDefault();
                                             event.stopPropagation();
                                             setSelectedVariant(item);
                                             productSelected.selectedItem = {
                                              ...productSelected.selectedItem,
                                              ...item,
                                            };
                                            productChange(productSelected, index, productSelected.category);
                                           }}
                                         >
                                           <ShapesVariant
                                             type="area"
                                             item={item}
                                             selected={
                                                (selectedVariant &&
                                                  selectedVariant.area &&
                                                  selectedVariant.area.key) ===
                                                (item && item.area.key)
                                              }
                                           ></ShapesVariant>
                                         </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
            
                              <div className="mt-4">
                                <button
                                  type="button"
                                  className="inline-flex justify-center rounded-md border border-transparent bg-stone-500 px-4 py-2 text-sm font-medium text-white hover:bg-black focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                  onClick={() => {
                                    setOpen(false)
                                  }}
                                >
                                  Done
                                </button>
                              </div>
                            </div>
                          </Transition.Child>
                        </div>
                      </div>
                    </Dialog>
                  </Transition>
                  </div>)
                :''    
              }
                <div className="flex w-full justify-end">
                    <button 
                        className="bg-white p-1 rounded-t-md mr-1" 
                        onClick={() => {
                            setIsDrawerOpen(!isDrawerOpen)
                        }}
                    >
                        {
                            isDrawerOpen ? 
                            <ChevronDoubleDownIcon 
                                className={`h-6 w-6 text-stone-700 group-hover:text-opacity-100 transition ease-in-out duration-150`}
                                aria-hidden="true"
                            ></ChevronDoubleDownIcon>
                            : 
                            <ChevronDoubleUpIcon 
                                className={`h-6 w-6 text-stone-700 group-hover:text-opacity-100 transition ease-in-out duration-150`}
                                aria-hidden="true"
                            ></ChevronDoubleUpIcon>
                        }
                        
                    </button>
                </div>
                <div className="bg-white w-full">
                    <Tab.Group selectedIndex={tabIndex} onChange={setTabIndex}>
                        <div id='categoryTabParentDiv' className="relative flex w-full items-center">
                            <ScrollLeftButton/>
                            <Tab.List id='Product_category_list' className="flex pr-6 pl-2 mx-1.5 w-90 justify-between overflow-x-auto overflow-y-hidden scrollbar-hide md:w-full md:px-1 md:w-full md:space-x-1">
                            {categories.map((category,index) => 
                              <div>
                                <Tab
                                disabled={!category.active_product_count > 0}
                                key={category}
                                className={({ selected }) =>
                                    classNames(
                                    "w-full px-3 mx-2.5 py-2.5 text-sm leading-5 font-medium text-stone-700  whitespace-nowrap md:mx-0 md:whitespace-normal",
                                    "focus:outline-none",
                                    selected
                                        ? "text-stone-900 border-bottom"
                                        : "text-stone-500 hover:bg-white/[0.12] hover:text-stone-900",
                                    !category.active_product_count > 0 ? 'hidden' : ''
                                    )
                                }
                                >
                                {category.label}
                                </Tab>
                              </div>
                            )}
                            </Tab.List>
                            <ScrollRightButton/>
                        </div>
                        <Tab.Panels id='card_panel' className="mt-2 h-full w-full" >
                        {categories.map((category, idx) => (
                            <Tab.Panel
                                key={idx}
                                style={{'transition': 'max-height 0.5s ease-out'}}
                                className={classNames(
                                    "bg-white rounded-xl p-2 flex justify-center md:justify-start",
                                    isDrawerOpen ? "max-h-60" : "max-h-0"
                                )}
                            >
                            <div 
                                className={`flex lg:h-screen overflow-x-scroll w-full`}
                            >
                                {productList.map((product, productIndex) => (
                                    <div className="flex">
                                        {product.variants.map((item, index) => (
                                            <>
                                                {
                                                    (productIndex === 0 && index ===0) ?
                                                    <></> : 
                                                    <div className="flex h-3/5 items-center">
                                                        <Dot></Dot>
                                                    </div>
                                                
                                                }
                                                
                                                <MobileProductCard
                                                    key={index + "card"}
                                                    product={product}
                                                    category={category.category}
                                                    item={item}
                                                    onProductClick={() => {
                                                        product.selectedItem = product.shapeItems ? {...selectedVariant,...item} : item;
                                                        product.category = category
                                                        productChange(product, productIndex, category.category);
                                                    }}
                                                    onDetailsClick={() => {
                                                        setSelectedProduct(product)
                                                        setIsDetailsVisible(true)
                                                        setSelectedItem(item)
                                                    }}
                                                ></MobileProductCard>
                                            </>
                                        ))}
                                    </div>
                                ))}
                            </div>
                            </Tab.Panel>
                        ))}
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
            <ProductDetailTray 
                item={selectedItem}
                product={selectedProduct} 
                visible={isDetailsVisible}
                onTrayClose={() => {
                    setIsDetailsVisible(false)
                }}
            ></ProductDetailTray>
        </div>
  );
};

export {MobileCatalogue}
